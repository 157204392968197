// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect, useRef, useState } from "react"
import { HomeHeader, AppButton, AppInput, Label } from "../../components"
import { Grid, MenuItem, Select, Autocomplete as TextAutocomplete, TextField } from "@mui/material"
import { ReactComponent as SearchGrey } from "../../assets/svg/searchGrey.svg"
import { useNavigate } from "react-router-dom"
import AppContext from "../../Context"
import { useSnackbar } from "notistack"
import AvatarIcon from "../../assets/images/avatar.png"
import { ReactComponent as CheckIcon } from "../../assets/svg/Check.svg"
import { getCircuitAffiliation, getCircuitParticipation, getTargetPlayerLevel, getTargetTeamCompetition, getTeamTravel, searchUserProfile } from "../../api/auth"
import { useSearchParams } from "react-router-dom"
import { COLORS, MAP_API_KEY } from "../../constants"
import Autocomplete from "react-google-autocomplete"
import { getPayload } from "../../utils/formattedPayload"
import { getError } from "../../utils/getError"
import MultipleSelect from "../../components/MultipleSelect"
import { ftValues, inValues } from "../../utils/mixed"
export default function SearchPage({ }) {
  const { enqueueSnackbar } = useSnackbar()
  const autoCompleteRef = useRef()
  const token = localStorage.getItem("token")
  let [searchParams, setSearchParams] = useSearchParams()
  const queryParam = searchParams.get("text")
  const guestRef = useRef(null)
  const navigate = useNavigate()
  const { _getPositions,positionList, grades, sports, schools, _getSchools } = useContext(AppContext)
  const [state, setState] = useState({
    selectedClass: "",
    city: "",
    searchText: "",
    selectedPostion: "",
    address: "",
    userList: [],
    active: false,
    team_travel: "",
    target_player_level: '',
    target_team_competition: "",
    teamTravel: [],
    targetPlayerLevel: [],
    targetTeamCompetition: [],
    circuit_affiliation: "",
    circuit_affiliation_list: [],
    circuit_participation: '',
    circuit_participation_list: [],
    height_in_min: '',
    height_ft_min: '',
    height_in_max: '',
    height_ft_max: '',
    sport: '',
    school_custom: '',
    school: '',
    coachList: [],
    userTeamList: [],
    coach_user_id: '',
    my_trainer_custom: '',
    my_trainer: '',
    team_custom: "",
    team: "",
    max_cost: '',
  })

  const {
    city,
    selectedClass,
    selectedPostion,
    searchText,
    userList,
    active,
    address,
    team_travel,
    target_player_level,
    target_team_competition,
    teamTravel,
    targetPlayerLevel,
    targetTeamCompetition,
    circuit_affiliation,
    circuit_affiliation_list,
    circuit_participation,
    circuit_participation_list,
    height_in_min,
    height_ft_min,
    height_in_max,
    height_ft_max,
    sport,
    school_custom,
    school,
    coachList,
    coach_user_id,
    my_trainer_custom,
    my_trainer,
    userTeamList,
    team_custom,
    team,
    max_cost,
  } = state

  useEffect(() => {
    if (!!sport) {
      _getPositions(`?sport=${sport}`)
    }
  }, [sport])

  useEffect(() => {
    if (queryParam) {
      handleChange("searchText", queryParam)
      _getProfile(queryParam, false)
    }
  }, [queryParam])

  const getData = async () => {
    try {
      const res = await getCircuitAffiliation(token)
      const res1 = await getCircuitParticipation(token)
      handleChange("circuit_affiliation_list", res?.data)
      handleChange("circuit_participation_list", res1?.data)
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const _getAllCoaches = async (payload) => {
    try {
      const qs = payload ?? ''
      const res = await searchUserProfile(qs, token)
      handleChange("coachList", [...(res?.data?.results ?? [])])
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const _getTeamTravel = async () => {
    try {
      const token = localStorage.getItem("token")

      const res = await getTeamTravel(token)
      handleChange("teamTravel", res?.data?.results)
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const _getTargetTeamCompetition = async () => {
    try {
      const token = localStorage.getItem("token")

      const res = await getTargetTeamCompetition(token)
      handleChange("targetTeamCompetition", res?.data?.results)
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const _getTargetPlayerLevel = async () => {
    try {
      const token = localStorage.getItem("token")

      const res = await getTargetPlayerLevel(token)
      handleChange("targetPlayerLevel", res?.data?.results)
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  useEffect(() => {
    _getTeamTravel()
    _getTargetTeamCompetition()
    _getTargetPlayerLevel()
    getData()
  }, [])

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const handleApply = () => {
    _getProfile(searchText, true)
  }

  const handleReset = () => {
    handleChange("selectedPostion", "")
    handleChange("selectedClass", "")
    handleChange("city", "")
    handleChange("address", "")
    handleChange("team_travel", '')
    handleChange("target_team_competition", "")
    handleChange("circuit_affiliation", "")
    handleChange("target_player_level", "")
    handleChange("circuit_participation", '')
    handleChange("max_cost", '')
    handleChange("height_ft_max", '')
    handleChange("height_ft_min", '')
    handleChange("height_in_max", '')
    handleChange("height_in_min", '')
    handleChange("school", '')
    handleChange("team", '')
    handleChange("coach_user_id", '')
    handleChange("my_trainer", '')
    handleChange("sport", '')
    _getProfile(searchText, false, true)
  }

  const _getProfile = async (searchText, filtered, reset) => {
    try {
      handleChange("searchText", searchText ? searchText : "")
      setSearchParams({ text: searchText })
      const token = localStorage.getItem("token")
      const queryParams = {
        search: searchText,
        city: city,
        player__grade: selectedClass,
        player__first_position_play__name: selectedPostion,
        role: active,
        organization__circuit_affiliation: circuit_affiliation,
        organization__circuit_participation: circuit_participation,
        organization__team_travel: team_travel,
        organization__target_team_competition: target_team_competition,
        organization__target_player_level: target_player_level,
        player__school: school,
        sport,
        team,
        height_min: height_in_min ? height_ft_min + "." + height_in_min : height_ft_min,
        height_max: height_in_max ? height_ft_max + "." + height_in_max : height_ft_max,
        player__my_trainer: coach_user_id,
        max_cost
      }
      const payload = reset ? `?role=${active}` : getPayload(queryParams)
      const res = await searchUserProfile(payload, token)
      handleChange("userList", res?.data?.results)
      // }
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const handleSearch = async place => {
    try {
      if (!place?.geometry) {
        alert("Please select location from list first")
        return
      }
      const res = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${place?.geometry.location?.lat()},${place?.geometry.location?.lng()}&key=${MAP_API_KEY}`
      )
      const res1 = await res.json()
      const address = place?.formatted_address
      var address_components = res1?.results[0]?.address_components
      let dState = ""
      let country = ""
      let city = ""
      let postal_code = ""
      if (address_components !== undefined) {
        const addrComp = address_components
        for (let i = 0; i < addrComp.length; ++i) {
          var typ = addrComp[i]?.types[0]
          if (typ === "administrative_area_level_1") {
            dState = addrComp[i]?.long_name
          } else if (typ === "locality") {
            city = addrComp[i]?.long_name
          } else if (typ === "country") {
            country = addrComp[i]?.long_name
          } else if (typ === "postal_code") {
            postal_code = addrComp[i]?.short_name
          }
        }
      }
      handleChange("city", city)
      handleChange("address", address)
    } catch (error) {
      alert(error?.message)
    }
  }

  const _getProfileDropdown = async searchText => {
    try {
      // handleChange("searchText", searchText)
      const token = localStorage.getItem("token")
      if (searchText) {
        const res = await searchUserProfile(
          `?search=${searchText}&role=Organization`,
          token
        )
        handleChange(
          "userTeamList",
          res?.data?.results.map(p => {
            return {
              title: p?.organization?.organization_name,
              value: p?.organization.id,
              ...p
            }
          })
        )
      }
    } catch (error) {
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const onInputChange = (event, value, reason) => {
    handleChange("team_custom", value != 'undefined' ? value : "")
    if (value && value != 'undefined') {
      _getProfileDropdown(value)
    }
  }

  const handleUserClick = user => {
    const role = user?.role
    const route =
      role === "Player"
        ? `/player-profile/${user?.id}`
        : role === "Organization"
          ? `/organization-profile/${user?.id}`
          : role === "Coach"
            ? `/coach-profile/${user?.id}`
            : `/parent-profile/${user?.id}`
    navigate(route)
  }

  const list = positionList?.map((item, index) => (
    <option key={index} value={item?.id}>
      {item?.name}
    </option>
  ))
  const list1 = grades?.map((item, index) => (
    <option key={index} value={item?.id}>
      {item?.name}
    </option>
  ))
  const option1 = <option value={""}>Select Position</option>
  const option2 = <option value={""}>Select Grades</option>
  const positionPlayOptions = [option1, ...list]
  const gradesPlayOptions = [option2, ...list1]


  const option3 = (
    <option value={""} disabled>
      Select Team Travel
    </option>
  )
  const option4 = (
    <option value={""} disabled>
      Select Target Player Level[s]
    </option>
  )
  const option5 = (
    <option value={""} disabled>
      Select Target Team Competition
    </option>
  )
  const option7 = (
    <option value={""} disabled>
      Select Circuit Participation
    </option>
  )
  const option6 = (
    <MenuItem value={""} disabled>
      Select Circuit Affiliation
    </MenuItem>
  )

  const list3 = teamTravel?.map((item, index) => (
    <option key={`${index}-tt`} value={item?.id}>
      {item?.name}
    </option>
  ))
  const list4 = targetPlayerLevel?.map((item, index) => (
    <option key={`${index}-tpl`} value={item?.id}>
      {item?.name}
    </option>
  ))
  const list5 = targetTeamCompetition?.map((item, index) => (
    <option key={`${index}-ttc`} value={item?.id}>
      {item?.name}
    </option>
  ))
  const list7 = circuit_participation_list?.map((item, index) => (
    <option key={`${index}-ttc`} value={item?.id}>
      {item?.name}
    </option>
  ))
  const list6 = circuit_affiliation_list?.map((item, index) => (
    <MenuItem key={`${index}-ttc`} value={item?.id}>
      <>
        <img alt={item?.name} src={item?.image} className="circuit_affiliation_image" />
        {item?.name}
      </>
    </MenuItem>
  ))
  const option8 = <option value={""}>Select Sport</option>
  const option9 = <option value={""}>Select Height in ft</option>
  const option10 = <option value={""}>Select Height in inch</option>

  const listSports = sports?.map((item, index) => (
    <option key={index} value={item?.id}>
      {item?.name}
    </option>
  ))

  const listftValues = ftValues?.map((item, index) => (
    <option key={index} value={item}>
      {item}
    </option>
  ))

  const listinValues = inValues?.map((item, index) => (
    <option key={index} value={item}>
      {item}
    </option>
  ))

  const teamTravelOptions = [option3, ...list3]
  const targetPlayerLevelOptions = [option4, ...list4]
  const targetTeamCompetitionOptions = [option5, ...list5]
  const circuitAffiliationOptions = [option6, ...list6]
  const circuitParticipationList = [option7, ...list7]
  const sportsOptions = [option8, ...listSports]
  const ftValuesOptions = [option9, ...listftValues]
  const inValuesOptions = [option10, ...listinValues]

  return (
    <div className="">
      <HomeHeader dashboard />
      <section className="container pb-3 mb-0 bg-transparent divCenter">
        <Grid container spacing={3} className="width80">
          <Grid item xs={12} md={7}>
            <div className="searchInputBookmarkBox mb-4">
              <input
                className="searchInput"
                placeholder="Search..."
                onChange={e => _getProfile(e.target.value, false)}
                value={searchText}
              />
              <div className="saerchIconHeaderDiv">
                <SearchGrey />
              </div>
            </div>
            <div className="mb-3 font-lexend font-20">Search Results</div>
            {userList?.length > 0 &&
              userList?.map((user, index) => (
                <Grid
                  key={index}
                  className="mb-5 cursor"
                  onClick={() => handleUserClick(user)}
                  container
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Grid container alignItems={"center"} item xs={8}>
                    <img
                      src={
                        user?.role === "Coach"
                          ? user?.coach?.picture
                          : user?.role === "Organization"
                            ? user?.organization?.logo
                            : user?.role === "Player"
                              ? user?.player?.picture
                              : user?.role === "Parent"
                                ? user?.parent?.picture
                                : AvatarIcon
                      }
                      className="searchUserProfile"
                    />
                    <div>
                      {user?.name ||
                        user?.first_name + " " + user?.last_name ||
                        "No Name"}
                    </div>
                  </Grid>
                  {user?.role && (
                    <div
                      className={
                        user?.role === "Player"
                          ? "playerRoleText"
                          : user?.role === "Organization"
                            ? "organizationRoleText"
                            : user?.role === "Parent"
                              ? "parentRoleText"
                              : "coachRoleText"
                      }
                    >
                      {user?.role === "Parent" ? "Community" : user?.role}
                    </div>
                  )}
                </Grid>
              ))}
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="mb-2">
              <p className={"font-20"}>Filters</p>
            </div>
            <Grid container className="mb-4">
              <AppButton
                width={100}
                height={35}
                title={
                  <div>
                    {active === "Player" && (
                      <CheckIcon style={{ marginBottom: 3 }} />
                    )}{" "}
                    Players{" "}
                  </div>
                }
                className={
                  active === "Player" ? "selectedFilter" : "filterButton"
                }
                onClick={() => {
                  handleChange("active", active === "Player" ? "" : "Player")
                }}
              />
              <AppButton
                width={140}
                height={35}
                className={
                  active === "Organization" ? "selectedFilter" : "filterButton"
                }
                title={
                  <div>
                    {active && <CheckIcon style={{ marginBottom: 3 }} />}{" "}
                    Organization{" "}
                  </div>
                }
                onClick={() =>
                  handleChange(
                    "active",
                    active === "Organization" ? "" : "Organization"
                  )
                }
              />
              <AppButton
                width={100}
                height={35}
                className={
                  active === "Coach"
                    ? "selectedFilter mt-2"
                    : "filterButton mt-2"
                }
                title={
                  <div>
                    {active && <CheckIcon style={{ marginBottom: 3 }} />} Coach{" "}
                  </div>
                }
                onClick={() =>
                  handleChange("active", active === "Coach" ? "" : "Coach")
                }
              />
              <AppButton
                width={140}
                height={35}
                className={
                  active === "Community"
                    ? "selectedFilter mt-2"
                    : "filterButton mt-2"
                }
                title={
                  <div>
                    {active && <CheckIcon style={{ marginBottom: 3 }} />} Community{" "}
                  </div>
                }
                onClick={() =>
                  handleChange("active", active === "Community" ? "" : "Community")
                }
              />
            </Grid>
            <>
              <Label text={"Location"} />
              <Autocomplete
                apiKey={MAP_API_KEY}
                className="locationSearch"
                title=""
                value={address}
                options={{ types: ["locality"] }}
                style={{ height: 40, backgroundColor: "#fff" }}
                onChange={e => {
                  handleChange("address", e?.target?.value)
                  handleChange("city", e?.target?.value)
                }}
                onPlaceSelected={place => {
                  handleSearch(place)
                }}
                placeholder=""
              />
            </>
            {active === "Community" && (
              <>
                <AppInput
                  label={"Sports"}
                  select
                  selectOptions={sportsOptions}
                  height={40}
                  onChange={handleChange}
                  className={"mt-3"}
                  value={sport}
                  name={"sport"}
                />
              </>
            )}
            {active === "Coach" && (
              <>
                <AppInput
                  label={"Sports"}
                  select
                  selectOptions={sportsOptions}
                  height={40}
                  onChange={handleChange}
                  className={"mt-3"}
                  value={sport}
                  name={"sport"}
                />
              </>
            )}
            {active === "Organization" && (
              <>
                <Grid item xs={12} sx={{ mb: 2, mt: 2 }}>
                  <AppInput
                    label={"Sports"}
                    select
                    selectOptions={sportsOptions}
                    height={40}
                    onChange={handleChange}
                    className={"mt-3"}
                    value={sport}
                    name={"sport"}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mb: 2, mt: 2 }}>
                  <AppInput
                    label={"Max Cost"}
                    placeholder={"0"}
                    type={'number'}
                    height={40}
                    onChange={handleChange}
                    value={max_cost}
                    className={"mt-3"}
                    name={"max_cost"}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mb: 2, mt: 2 }}>
                  <AppInput
                    label={"Circuit participation"}
                    select
                    selectOptions={circuitParticipationList}
                    height={40}
                    onChange={handleChange}
                    value={circuit_participation}
                    name={"circuit_participation"}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Label fontNormal text={"Circuit Affiliation"} />
                  <Select
                    defaultValue={circuit_affiliation}
                    value={circuit_affiliation}
                    style={{
                      border: "none",
                      backgroundColor: "#fff",
                      borderRadius: 10,
                      color: "#000",
                      height: 40,
                      width: "100%"
                    }}
                    placeholder={"placeholder"}
                    onChange={value => handleChange("circuit_affiliation", value.target.value)}
                  >
                    {circuitAffiliationOptions}
                  </Select>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <AppInput
                    label={"Target Team Competition"}
                    select
                    selectOptions={targetTeamCompetitionOptions}
                    height={40}
                    onChange={handleChange}
                    value={target_team_competition?.id || target_team_competition}
                    name={"target_team_competition"}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <AppInput
                    label={"Target Player Level"}
                    select
                    selectOptions={targetPlayerLevelOptions}
                    height={40}
                    onChange={handleChange}
                    value={target_player_level}
                    name={"target_player_level"}
                  />
                  {/* <MultipleSelect
                    label={"Target Player Level[s]"}
                    data={targetPlayerLevel}
                    height={40}
                    onChange={e => handleChange("target_player_level", e)}
                    value={target_player_level?.map(v => (v?.id ? v.id : v))}
                    name={"target_player_level"}
                    placeholder="Select Player Level[s]"
                  /> */}
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <AppInput
                    label={"Team Travel"}
                    select
                    selectOptions={teamTravelOptions}
                    height={40}
                    onChange={handleChange}
                    value={team_travel?.id || team_travel}
                    name={"team_travel"}
                  />
                </Grid>
              </>)}
            {active === "Player" && (
              <>
                <AppInput
                  label={"Min. Height (Ft)"}
                  placeholder={"Ft"}
                  height={40}
                  onChange={handleChange}
                  value={height_ft_min}
                  select
                  selectOptions={ftValuesOptions}
                  className={"mt-3"}
                  name={"height_ft_min"}
                />
                <AppInput
                  label={"Min. Height (In)"}
                  placeholder={"In"}
                  height={40}
                  onChange={handleChange}
                  value={height_in_min}
                  select
                  selectOptions={inValuesOptions}
                  className={"mt-3"}
                  name={"height_in_min"}
                />
                <AppInput
                  label={"Max. Height (Ft)"}
                  placeholder={"Ft"}
                  height={40}
                  onChange={handleChange}
                  select
                  selectOptions={ftValuesOptions}
                  value={height_ft_max}
                  className={"mt-3"}
                  name={"height_ft_max"}
                />
                <AppInput
                  label={"Max. Height (In)"}
                  placeholder={"In"}
                  height={40}
                  select
                  selectOptions={inValuesOptions}
                  onChange={handleChange}
                  className={"mt-3"}
                  value={height_in_max}
                  name={"height_in_max"}
                />
                <Label text={"Team"} className={'mt-3'} fontNormal />
                <TextAutocomplete
                  freeSolo
                  fullWidth
                  id="combo-box-demo"
                  options={userTeamList}
                  onInputChange={onInputChange}
                  inputValue={team_custom}
                  getOptionLabel={option => {
                    return option.title
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      style={{ borderRadius: 10 }}
                    />
                  )}
                  sx={{
                    "& legend": { display: "none" },
                    "& .MuiInputLabel-shrink": {
                      opacity: 0,
                      transition: "all 0.2s ease-in"
                    },
                    "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9",
                      borderWidth: 1
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9"
                    },
                    "& fieldset": {
                      borderRadius: 2,
                      maxHeight: "40px",
                      marginTop: "5px"
                    }
                  }}
                  onChange={(e, newValue) =>
                    handleChange("team", newValue)
                  }
                  name={"team"}
                  value={team}
                />
                <Label text={"School"} className={"mt-3"} fontNormal />
                <TextAutocomplete
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  value={school_custom}
                  onChange={(event, newValue) => {
                    handleChange("school_custom", newValue)
                    handleChange(
                      "school",
                      schools[event?.target?.dataset?.optionIndex]?.id
                    )
                  }}
                  inputValue={school_custom}
                  onInputChange={(event, newInputValue) => {
                    if (newInputValue?.length > 2) {
                      const payload = `?name=${newInputValue}&l=${50}&o=${0}`
                      _getSchools(payload)
                    }
                    handleChange("school_custom", newInputValue)
                    handleChange("school", "")
                  }}
                  options={schools?.map(
                    option => `${option.name}, ${option.city}, ${option.state}`
                  )}
                  // getOptionLabel={(option) => option.team_name}
                  label={false}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder="Search School"
                      size="small"
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#D9D9D9",
                          borderWidth: 1
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#D9D9D9"
                        }
                      }}
                      label=""
                      InputLabelProps={{ shrink: false }}
                      InputProps={{
                        ...params.InputProps,
                        type: "search"
                      }}
                    />
                  )}
                />
                <Label text={"Trainer / Development Coach"} className={'mt-3'} fontNormal />
                <TextAutocomplete
                  freeSolo
                  fullWidth
                  id="free-solo-2-demo"
                  disableClearable
                  onInputChange={(event, value) => {
                    if (value?.length > 2) {
                      const payload = `?role=Coach,Organization&search=${value}`
                      _getAllCoaches(payload)
                    }
                    handleChange("my_trainer_custom", value)
                    handleChange("my_trainer", '')
                    handleChange("coach_user_id", '')
                  }}
                  inputValue={my_trainer_custom}
                  value={my_trainer_custom}
                  label={false}
                  options={coachList?.map(
                    option => `${option?.coach?.user?.name || option?.organization?.organization_name}`
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      style={{ borderRadius: 10 }}
                    />
                  )}
                  sx={{
                    "& legend": { display: "none" },
                    "& .MuiInputLabel-shrink": {
                      opacity: 0,
                      transition: "all 0.2s ease-in"
                    },
                    "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9",
                      borderWidth: 1
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9"
                    },
                    "& fieldset": {
                      borderRadius: 2,
                      maxHeight: "40px",
                      marginTop: "5px"
                    }
                  }}
                  onChange={(event, newValue) => {
                    handleChange(
                      "my_trainer",
                      coachList[event?.target?.dataset?.optionIndex]?.id ?
                        coachList[event?.target?.dataset?.optionIndex]?.id :
                        coachList[event?.target?.dataset?.optionIndex]?.id
                    )
                    handleChange(
                      "coach_user_id",
                      coachList[event?.target?.dataset?.optionIndex]?.id ?
                        coachList[event?.target?.dataset?.optionIndex]?.id :
                        coachList[event?.target?.dataset?.optionIndex]?.id
                    )
                    handleChange("my_trainer_custom", newValue)
                  }}
                />
                <AppInput
                  label={"Sports"}
                  select
                  selectOptions={sportsOptions}
                  height={40}
                  onChange={handleChange}
                  className={"mt-3"}
                  value={sport}
                  name={"sport"}
                />
                <AppInput
                  label={"Position"}
                  select
                  selectOptions={positionPlayOptions}
                  height={40}
                  className={"mt-3"}
                  onChange={handleChange}
                  value={selectedPostion}
                  name={"selectedPostion"}
                />
                <AppInput
                  label={"Grades"}
                  select
                  selectOptions={gradesPlayOptions}
                  height={40}
                  className={"mt-3"}
                  onChange={handleChange}
                  value={selectedClass}
                  name={"selectedClass"}
                />
              </>
            )}
            <Grid container justifyContent={"space-between"} className="mt-4">
              <AppButton
                width={"48%"}
                height={45}
                title={<div>Reset</div>}
                className={""}
                onClick={handleReset}
              />
              <AppButton
                width={"48%"}
                height={45}
                backgroundColor={COLORS.primary}
                color={COLORS.white}
                title={<div>Apply</div>}
                onClick={handleApply}
              />
            </Grid>
          </Grid>
        </Grid>
      </section>
    </div>
  )
}
