import React, { useContext, useEffect, useRef, useState } from "react";
import { DeleteCalendarEventModal, HomeHeader } from "../../components";
import { useNavigate } from "react-router-dom";
import { Popover, IconButton } from "@mui/material";
import { CloseOutlined, DeleteOutline } from "@mui/icons-material";
import AppContext from "../../Context";
import { useSnackbar } from "notistack";
import { allSchedules, deleteGameSchedules, deleteTryout, getGameSchedules, updatePracticeSchedules } from "../../api/auth";
import { ReactComponent as TimeIcon } from "../../assets/svg/timeIcon.svg";
import { ReactComponent as DurationIcon } from "../../assets/svg/durationIcon.svg";
import { ReactComponent as LocationIcon } from "../../assets/svg/locationIcon.svg";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { getError } from "../../utils/getError";
const localizer = momentLocalizer(moment);
export default function AppCalendar({ }) {
  const { enqueueSnackbar } = useSnackbar();
  const guestRef = useRef(null);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

  const { isPlayer } = useContext(AppContext);
  const [state, setState] = useState({
    selectedClass: "",
    selectedLocation: "",
    loadingDelete: false,
    selectedEvent: null,
    gameSchedule: [],
  });

  const { gameSchedule, loadingDelete, selectedEvent } = state;

  useEffect(() => {
    getData();
  }, []);

  const getData = async (nextPage) => {
    try {
      handleChange("gameSchedule", []);
      handleChange("loading", true);
      const res = await allSchedules(``, token);
      const list = [];
      const list1 = [];
      res?.data?.practice_schedules?.forEach((pra) => {
        const removed = pra?.cancelled_events?.cancelled_events ? pra?.dates?.filter(val => !pra?.cancelled_events?.cancelled_events?.includes(val)) : pra?.dates;
        removed?.forEach((element) => {
          if (element) {
            list1.push({
              ...pra,
              start_date: element,
            });
          }
        });
      });
      const allitems = [
        ...(Array.isArray(list1) ? list1 : []),
        ...(Array.isArray(res?.data?.schedules) ? res?.data?.schedules : []),
        ...(Array.isArray(res?.data?.tryouts) ? res?.data?.tryouts : []),
      ];
      allitems?.forEach((element) => {
        if (element) {
          list.push({
            title:
              (element?.event_type === "practice_schedule"
                ? "Practice Schedule"
                : element?.event_type === "schedule"
                  ? "Game Schedule"
                  : "Tryout") +
              ", " +
              element?.location,
            bgColor: "transparent",
            color: "#000",
            start: new Date(
              moment(
                (element?.event_type === "practice_schedule"
                  ? element?.start_date
                  : element?.date) +
                " " +
                (element?.event_type === "practice_schedule"
                  ? element?.time
                  : element?.arrival_time)
              ).format()
            ),
            end: new Date(
              moment(
                (element?.event_type === "practice_schedule"
                  ? element?.start_date
                  : element?.date) +
                " " +
                (element?.event_type === "schedule"
                  ? element?.game_time
                  : element?.event_type === "try_out"
                    ? element?.arrival_time
                    : element?.time)
              ).format()
            ),
            desc: "Appointment desciption3",
            ...element,
          });
        }
      });
      handleChange("gameSchedule", list);
      handleChange("loading", false);
    } catch (error) {
      handleChange("loading", false);
      console.log('error', error);
      enqueueSnackbar(error ? getError(error) : 'something went wrong', {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalVisible(false)
  }
  const handleClick = (event, selectedEvent) => {
    setAnchorEl(event.currentTarget);
    handleChange("selectedEvent", selectedEvent);
  };
  const handleClose = () => {
    setAnchorEl(null);
    handleChange("selectedEvent", null);
  };

  const handleDeleteEvent = async () => {
    console.log('selectedEvent', selectedEvent);
    try {
      handleChange("loadingDelete", true);
      if (selectedEvent?.event_type === "try_out") {
        await deleteTryout(selectedEvent?.id, token)
      } else if (selectedEvent?.event_type === "schedule") {
        await deleteGameSchedules(selectedEvent?.id, token)
      } else if (selectedEvent?.event_type === "practice_schedule") {
        const payload = {
          cancelled_events: {
            cancelled_events: [...(selectedEvent?.cancelled_events?.cancelled_events ?? []), selectedEvent?.start_date]
          }
        }
        await updatePracticeSchedules(selectedEvent?.id, payload, token)
      }
      handleCloseDeleteModal()
      getData()
      handleChange("loadingDelete", false);
      enqueueSnackbar("Event has been deleted", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (error) {
      handleChange("loadingDelete", false);
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
    setAnchorEl(null);
  };

  const printDuration = (start, end) => {
    var startTime = moment(start, "YYYY-MM-DD HH:mm:ss");
    var endTime = moment(end, "YYYY-MM-DD HH:mm:ss");

    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime));

    // duration in hours
    var hours = parseInt(duration.hours());

    // duration in minutes
    var minutes = parseInt(duration.asMinutes()) % 60;

    return hours > 0
      ? hours + " hour " + minutes + " minutes"
      : minutes + " minutes";
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="">
      <HomeHeader dashboard />
      <section className="container pb-3 mb-0 bg-transparent divCenter">
        <Calendar
          selectable
          localizer={localizer}
          events={gameSchedule}
          startAccessor="start"
          endAccessor="end"
          views={{
            month: true,
            week: true,
            day: true,
            agenda: false,
          }}
          onSelectEvent={(event, e) => handleClick(e, event)}
          style={{ height: 600,zIndex:1, width: "80%" }}
        // onSelectSlot={(slotInfo) => alert(`selected slot: \n\nstart ${slotInfo.start.toLocaleString()} ` + `\nend: ${slotInfo.end.toLocaleString()}`)}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          classes={{ paper: "event-paper" }}
          sx={{ width: 600 }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <div className="p-3 bg-white rounded">
            <div className="rowBetween">
              <div className="font-bold font-18">Event Details</div>
              <div className="d-flex">
                {
                  !isPlayer &&
                  <IconButton onClick={() => setIsDeleteModalVisible(true)}>
                    <DeleteOutline />
                  </IconButton>
                }
                <IconButton onClick={handleClose}>
                  <CloseOutlined />
                </IconButton>
              </div>
            </div>
            <div className="font-bold font-16 mb-4">
              {selectedEvent?.event_type === "try_out"
                ? "Tryout"
                : selectedEvent?.event_type === "schedule"
                  ? "Game With"
                  : "Practice Session"}
            </div>
            {selectedEvent?.event_type === "schedule" && (
              <div className="d-flex align-items-center mb-4">
                <img
                  src={selectedEvent?.opponent?.organization_data?.logo}
                  className="eventTeamLogo"
                />
                <div>
                  {selectedEvent?.opponent?.team_name ||
                    selectedEvent?.opponent_team_custom}
                </div>
              </div>
            )}
            <div className="mb-3">
              <TimeIcon className="mr-2" />{" "}
              {moment(
                selectedEvent?.event_type === "practice_schedule"
                  ? selectedEvent?.time
                  : selectedEvent?.arrival_time,
                "HH:mm:ss"
              ).format("hh:mm a")}
            </div>
            <div className="mb-3">
              <DurationIcon className="mr-2" />{" "}
              {selectedEvent?.event_type !== "schedule"
                ? selectedEvent?.duration +" hrs"
                : printDuration(
                  selectedEvent?.date + " " + selectedEvent?.arrival_time,
                  selectedEvent?.date + " " + selectedEvent?.game_time
                )}
            </div>
            <div className="d-flex align-items-center">
              <div className="mb-3">
                <LocationIcon className="mr-2" /> {selectedEvent?.location}
              </div>
              {selectedEvent?.latitude && (
                <div
                  className="viewonmap"
                  onClick={() => {
                    window.open(
                      "https://maps.google.com?q=" +
                      selectedEvent?.latitude +
                      "," +
                      selectedEvent?.longitude
                    );
                  }}
                >
                  View on Map
                </div>
              )}
            </div>
            {selectedEvent?.notes && (
              <div className="mb-3">Notes: {selectedEvent?.notes}</div>
            )}
          </div>
        </Popover>
      </section>
      <DeleteCalendarEventModal
        visible={isDeleteModalVisible}
        loadingDelete={loadingDelete}
        handleCloseModal={handleCloseDeleteModal}
        handleDeleteEvent={handleDeleteEvent}
      />
    </div>
  );
}
