// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { AppButton, DeleteChatModal, HomeHeader, Loader, MessageModal } from "../../components"
import { Divider, Box, Grid, IconButton, Checkbox } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import AppContext from "../../Context"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Link } from "react-router-dom"
import { ReactComponent as MessageEdit } from "../../assets/svg/messageEdit.svg"
import { ReactComponent as SmileIcon } from "../../assets/svg/smile.svg"
import { ReactComponent as AttachmentIcon } from "../../assets/svg/attachment.svg"
import { ReactComponent as InfoIcon } from "../../assets/svg/info.svg"
import { ReactComponent as SendIcon } from "../../assets/svg/sendIcon.svg"
import { ReactComponent as PencilBlue } from "../../assets/svg/pencilBlue.svg"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { COLORS } from "../../constants"
import {
  addReaction,
  deleteChat,
  getChannelMessages,
  getChannels,
  leaveGroup,
  publishMessage,
  readChannel,
  searchMessages,
  updateChannel
} from "../../api/auth"
import moment from "moment"
import { ReactComponent as SearchGrey } from "../../assets/svg/searchGrey.svg"
import AvatarIcon from "../../assets/images/avatar.png"
import { getError } from "../../utils/getError"
import EmojiPicker, { Categories } from "emoji-picker-react"
import { usePubNub } from "pubnub-react"
import { useSnackbar } from "notistack"
import ChatContent from "./ChatContent"

export default function Messages({ }) {
  const navigate = useNavigate()
  const pubnub = usePubNub()
  const { id } = useParams()
  const inputRef = useRef()
  const divRef = useRef(null)
  const hiddenFileInput = useRef(null)
  const { enqueueSnackbar } = useSnackbar()

  const { user, _getChannelUnreadCount } = useContext(AppContext)
  const [messages, setMessages] = useState([])

  const [state, setState] = useState({
    loading: false,
    show: false,
    searchText: "",
    selectedChat: null,
    message: "",
    currentChannelData: null,
    currentChannel: "",
    searchMemberText: "",
    filteredList: [],
    messageList: [],
    createGroup: false,
    showGroup: false,
    changeNameGroup: false,
    addMemberGroup: false,
    loadingLeave: false,
    loadingGetting: false,
    loadingChat: false,
    isSearching: false,
    isSearch: false,
    loadingReact: false,
    showDeleteChatConfirm: false,
    loadingDeleteChat: false
  })

  const {
    searchText,
    selectedChat,
    show,
    loading,
    loadingGetting,
    loadingLeave,
    addMemberGroup,
    changeNameGroup,
    createGroup,
    filteredList,
    allChannels,
    message,
    showGroup,
    searchMemberText,
    currentChannelData,
    currentChannel,
    loadingChat,
    isSearching,
    isSearch,
    messageList,
    loadingReact,
    showDeleteChatConfirm,
    loadingDeleteChat
  } = state

  useEffect(() => {
    _getChannels()
    _readChannel()
    _getChannelUnreadCount()
  }, [])

  useEffect(() => {
    if (id) {
      _getChannelMessages()
      _getChannelUnreadCount()
      _readChannel()
    }
  }, [id])


  const messageIdFromHash = useMemo(
    () => window.location.hash.split('#msg-')[1],
    [window.location.hash]
  );

  const messageRefs = useRef({});

  useEffect(() => {
    if (messageIdFromHash) {
      // Find the message by ID and scroll into view
      const messageElement = messageRefs.current[messageIdFromHash];
      if (messageElement) {
        messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [messageIdFromHash, messages]); // Re-run when messageIdFromHash or messages change


  const filtered = async (name, value) => {
    handleChange(name, value)
    if (value) {
      handleChange("isSearch", true)
      handleChange("isSearching", true)
      const payload = `?search=${value}`
      const res = await searchMessages(payload)
      handleChange('filteredList', res?.data?.channels);
      handleChange('messageList', res?.data?.messages);

    } else {
      _getChannels()
      handleChange('messageList', []);
      handleChange('filteredList', allChannels);
      handleChange("isSearching", false)
      handleChange("isSearch", false)
    }
  }
  const getOtherUser = users => {
    const found = users?.filter(e => e?.id !== user?.id)
    return found?.length > 0 && found[0]
  }

  const getOtherUser1 = id => {
    const found = currentChannelData?.users?.filter(e => e?.id !== id)
    return found?.length > 0 && found[0]
  }

  const getLatest = list => {
    return list?.sort(function (a, b) {
      return (
        new Date(b?.last_message?.timetoken) -
        new Date(a?.last_message?.timetoken)
      )
    })
  }
  const sendMessage = async message => {
    if (message) {
      const payload = new FormData()
      payload.append("text", message)
      payload.append("channel", id)
      const res = await publishMessage(payload)
      if (res?.data?.status === "ERROR") {
        alert(res?.data?.message)
        return
      }
      handleChange("message", "")
      handleGetMessage()
    }
  }

  const _readChannel = async () => {
    try {
      if (id) {
        const payload1 = {
          channel_id: id
        }
        await readChannel(payload1)
      }
    } catch (error) {
      getError(error)
    }
  }
  const _getChannelMessages = async () => {
    try {
      handleChange("loadingGetting", true)
      const payload = `?channel_id=${id}`
      const res = await getChannelMessages(payload)
      // _getChannelUnreadCount()
      handleChange("currentChannelData", res?.data)
      handleChange("selectedChat", res?.data)
      handleChange("currentChannel", res?.data?.channel_name)
      setMessages(res?.data?.messages)
      handleChange("loadingGetting", false)
    } catch (error) {
      handleChange("loadingGetting", false)
      getError(error)
    }
  }
  const _leaveGroup = async () => {
    try {
      handleChange("loadingLeave", true)
      const payload = {
        channel: id,
        users: [user?.id]
      }
      await leaveGroup(payload)
      _getChannels()
      handleChange("selectedChat", null)
      handleChange("showGroup", false)
      handleChange("loadingLeave", false)
      navigate("/messages")
      enqueueSnackbar("You left a group", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loadingLeave", false)
      getError(error)
    }
  }

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const handleGetMessage = (event) => {
    console.log('event', event);
    if (event) {
      let list = []
      const found = allChannels?.find(e => e?.channel_name === event?.channel)
      const removed = allChannels?.filter(e => e?.channel_name !== event?.channel)
      const channel = {
        ...found,
        last_message: {
          ...found?.last_message,
          text: event?.message?.content,
          file: event?.message?.file?.url,
          timetoken: Number(event?.timetoken) / 10000
        }
      }
      list = [...removed, channel]
      handleChange("allChannels", list)
      handleChange("filteredList", list)
      _getChannelMessages()
    }
    // _getChannels()
    // _getChannelUnreadCount()
  }
  const handleClose = value => {
    handleChange("createGroup", value)
    handleChange("addMemberGroup", value)
    handleChange("changeNameGroup", value)
  }

  useEffect(() => {
    const listenerParams = { message: handleGetMessage }
    pubnub.addListener(listenerParams)
    const channels = []
    allChannels?.forEach(element => {
      if (element?.channel_name) {
        channels.push(element?.channel_name?.toString())
      }
    })
    pubnub.subscribe({ channels: channels })
    return () => {
      pubnub.unsubscribe({ channels: [currentChannel] })
      pubnub.removeListener(listenerParams)
    }
  }, [currentChannel])

  const _updateChannel = async (payload) => {
    try {
      handleChange("loadingChat", true)
      const res = await updateChannel(id, payload)
      handleChange("loadingChat", false)
      handleChange("currentChannelData", res?.data)
      // _getChannels()
      // _getChannelMessages()
    } catch (error) {
      handleChange("loadingChat", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const _addReaction = async (emoji, message_id, handleClose) => {
    try {
      const payload = {
        emoji,
        message_id
      }
      handleChange("loadingReact", message_id)
      const res = await addReaction(payload)
      handleChange("loadingReact", '')
      handleClose()
      setMessages(messages.map(_message =>
        _message.id === message_id
          ? res?.data
          : _message
      ))
      // _getChannels()
      // _getChannelMessages()
    } catch (error) {
      handleChange("loadingReact", '')
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }


  const _getChannels = async () => {
    try {
      handleChange("loading", true)
      const res = await getChannels()
      handleChange("allChannels", res?.data?.channels)
      handleChange("filteredList", res?.data?.channels)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _deleteChat = async () => {
    try {
      handleChange("loadingDeleteChat", true)
      const res = await deleteChat(selectedChat?.id)
      handleChange("selectedChat", null)
      handleChange("showGroup", false)
      handleChange("loadingDeleteChat", false)
      handleChange("showDeleteChatConfirm", false)
      navigate("/messages")
      _getChannels()
    } catch (error) {
      handleChange("loadingDeleteChat", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const openFirstChat = () => {
    if (filteredList?.length > 0) {
      handleChange("selectedChat", filteredList[0])
      navigate(`/messages/${filteredList[0]?.id}`)
    } else {
      alert("You don't have chat")
    }
  }

  const onClickEmoji = emoji => {
    setState(prevState => ({
      ...prevState,
      show: false,
      message: prevState.message + emoji?.emoji
    }))
  }

  const handleUserClick = user => {
    const role = user?.role
    const route =
      role === "Player"
        ? `/player-profile/${user?.id}`
        : role === "Organization"
          ? `/organization-profile/${user?.id}`
          : role === "Coach"
            ? `/coach-profile/${user?.id}`
            : `/parent-profile/${user?.id}`
    navigate(route)
  }
  const getImgSrc = user => {
    const role = user?.role
    switch (role) {
      case 'Coach':
        return user?.coach?.picture
      case 'Organization':
        return user?.organization?.logo
      case 'Player':
        return user?.player?.picture
      case 'Parent':
        return user?.parent?.picture
      default:
        return AvatarIcon
      // code block
    }
  }

  const onFileChange = async event => {
    const payload = new FormData()
    payload.append("text", message)
    payload.append("file", event.target.files[0])
    payload.append("file_type", event.target.files[0]?.type)
    payload.append("channel", id)
    const res = await publishMessage(payload)
    if (res?.data?.status === "ERROR") {
      alert(res?.data?.message)
      return
    }
    handleChange("message", "")
  }

  const openPicker = () => {
    hiddenFileInput.current.click()
  }

  const openNewGroup = () => {
    handleChange("createGroup", true)
  }

  const printName = user => {
    const isPlayer = user?.role === "Player"
    const isParent = user?.role === "Parent"
    const isCoach = user?.role === "Coach"
    const isOrganization = user?.role === "Organization"
    const ProfileDP = user?.picture_url || (isPlayer
      ? user?.player?.picture
      : isParent
        ? user?.parent?.picture
        : isOrganization
          ? user?.organization?.logo
          : isCoach
            ? user?.coach?.picture
            : user?.player?.picture || AvatarIcon)
    const ProfileName = user?.name || (isOrganization
      ? user?.organization?.organization_name
      : user?.name || "Guest")

    return { ProfileName, ProfileDP }
  }
  return (
    <div className="">
      <HomeHeader dashboard />
      {(user.role === "Parent" && id !== null) ||
        (undefined && (
          <div
            style={{ marginBottom: "1rem", marginTop: "1rem" }}
            className="container"
          >
            <div
              className="text-black font-14 d-flex p-2"
              style={{
                gap: 10,
                border: "1px solid #000",
                borderRadius: "12px"
              }}
            >
              <InfoIcon sx={{ color: "black", bgcolor: "#000" }} />
              <p>
                This player is linked to your account and your are view this
                account as <span className="font-bold">{user?.name}</span>
              </p>
            </div>
          </div>
        ))}
      <section className="container pb-3 mb-0 bg-transparent divCenter">
        <Grid container spacing={3} className="" sx={{ ml: 0 }}>
          <Grid
            item
            container
            xs={12}
            className={"noPadding"}
            sx={{
              borderRadius: 5,
              border: "1px solid #0000000F",
              height: "70vh"
            }}
          >
            <Grid
              item
              xs={12}
              md={3}
              className="noPadding"
              sx={{
                padding: 2,
                height: "80%",
                display: { xs: selectedChat ? "none" : "block", md: "block" },
                borderRight: { xs: "none", md: "1px solid #0000000F" }
              }}
            >
              <Grid
                container
                sx={{ p: 1 }}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item container xs={9} md={10} alignItems={"center"}>
                  <IconButton
                    aria-label="delete"
                    size=""
                    sx={{
                      bgcolor: COLORS.white,
                      borderRadius: 2,
                      color: "#222"
                    }}
                    onClick={() => navigate(-1)}
                  >
                    <ArrowBackIcon color="#222" />
                  </IconButton>
                  <Grid item>
                    <p className="font-medium font-18">Messages</p>
                  </Grid>
                </Grid>
                <Grid item>
                  <MessageEdit onClick={openNewGroup} className="cursor" />
                </Grid>
              </Grid>
              <Divider />
              <Grid container sx={{ p: 1, mt: 2 }}>
                <div className="searchInputHeaderBox" style={{ width: "100%" }}>
                  <input
                    className="searchInput"
                    placeholder="Search Messaegs"
                    value={searchText}
                    onChange={e => filtered("searchText", e.target.value)}
                  />
                  <div className="saerchIconHeaderDiv">
                    <SearchGrey />
                  </div>
                </div>
              </Grid>
              <Grid item sx={{ mt: 2, overflowY: "auto", height: "80%" }}>
                {
                  isSearch && filteredList?.length > 0 && <div className="font-bold ml-3 font-20">Chat</div>
                }
                {getLatest(filteredList)?.map((item, index) => (
                  <div
                    className={
                      id === item?.id?.toString()
                        ? "activeUserItem"
                        : "userItem"
                    }
                    key={index}
                    onClick={() => {
                      if (currentChannelData) {
                        handleChange("currentChannelData", null)
                      }
                      if (id === item?.id?.toString() && selectedChat?.id) {
                        handleChange("selectedChat", null)
                        navigate(`/messages`)
                      } else {
                        handleChange("selectedChat", item)
                        navigate(`/messages/${item?.id}`)
                      }
                      handleChange("showGroup", false)
                    }}
                  >
                    <div className="userNameDiv">
                      {item?.group_name === "Unnamed Chat Group" ? (
                        <img
                          src={printName(getOtherUser(item?.user_pictures))?.ProfileDP}
                          className={"c-pointer userprofile mr-3"}
                        />
                      ) : (
                        <div className="groupPic">
                          {item?.user_pictures?.map((user, index) => {
                            if (index < 2) {
                              return (
                                <img
                                  src={printName(user)?.ProfileDP}
                                  style={{ marginTop: index }}
                                  className={"groupuserprofile1"}
                                />
                              )
                            }
                            return null
                          })}
                        </div>
                      )}

                      <div className="nameDiv">
                        <div className="activeName">
                          {item?.group_name === "Unnamed Chat Group"
                            ? printName(getOtherUser(item?.user_pictures))?.ProfileName
                            : item?.group_name}
                        </div>
                        <div>
                          {item?.last_message?.file ? (
                            <AttachmentIcon />
                          ) : (
                            item?.last_message?.text
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flexEnd">
                      <div className="d-flex mb-2">
                        <span className="text-gray font-10">
                          {item?.last_message?.timetoken &&
                            moment(item?.last_message?.timetoken).fromNow()}
                        </span>
                      </div>
                      {!!item?.unread_messages_count && (
                        <div className="messageUnreadCount1">
                          {item?.unread_messages_count}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                {
                  isSearch && messageList?.length > 0 && <div className="font-bold ml-3 font-20">Messages</div>
                }
                {isSearch && getLatest(messageList)?.map((item, index) => {
                  const isMessageToFocus = Number(messageIdFromHash) === Number(item?.id);
                  return (
                    <div
                      className={
                        isMessageToFocus
                          ? "activeUserItem"
                          : "userItem"
                      }
                      key={index}
                      onClick={() => {
                        if (currentChannelData) {
                          handleChange("currentChannelData", null)
                        }
                        if (id === item?.channel?.id?.toString() && selectedChat?.id) {
                          handleChange("selectedChat", null)
                          navigate(`/messages`)
                        } else {
                          handleChange("selectedChat", item?.channel)
                          navigate(`/messages/${item?.channel?.id}#msg-${item?.id}`)
                        }
                        handleChange("showGroup", false)
                      }}
                    >
                      <div className="userNameDiv">
                        {item?.channel?.group_name === "Unnamed Chat Group" ? (
                          <img
                            src={printName(getOtherUser(item?.channel?.user_pictures))?.ProfileDP}
                            className={"c-pointer userprofile mr-3"}
                          />
                        ) : (
                          <div className="groupPic">
                            {item?.channel?.user_pictures?.map((user, index) => {
                              if (index < 2) {
                                return (
                                  <img
                                    src={printName(user)?.ProfileDP}
                                    style={{ marginTop: index }}
                                    className={"groupuserprofile1"}
                                  />
                                )
                              }
                              return null
                            })}
                          </div>
                        )}

                        <div className="nameDiv">
                          <div className="activeName">
                            {item?.channel?.group_name === "Unnamed Chat Group"
                              ? printName(getOtherUser(item?.channel?.user_pictures))?.ProfileName
                              : item?.channel?.group_name}
                          </div>
                          <div>
                            {item?.file ? (
                              <AttachmentIcon />
                            ) : (
                              item?.text
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flexEnd">
                        <div className="d-flex mb-2">
                          <span className="text-gray font-10">
                            {item?.timetoken &&
                              moment(item?.timetoken).fromNow()}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={showGroup ? 6 : 9}
              sx={{
                display: { xs: showGroup ? "none" : "block", md: "block" },
                height: "80%"
              }}
            >
              {!selectedChat && (
                <Grid
                  className="divCenter"
                  sx={{ display: { xs: "none", md: "flex" }, height: "100%" }}
                >
                  <div className="font-bold font-16">Your Messages</div>
                  <div className="mt-1 mb-3">
                    Start messaging with your friends
                  </div>
                  <AppButton
                    onClick={openFirstChat}
                    title={"Send Message"}
                    backgroundColor={COLORS.primary}
                    color={COLORS.white}
                    height={40}
                    width={150}
                  />
                </Grid>
              )}

              {!!selectedChat ? (
                <div className="messageContainer">
                  <div
                    className={
                      loadingGetting && !currentChannelData
                        ? "loadingMessageBox"
                        : "messageBox"
                    }
                  >
                    {loadingGetting && !currentChannelData && (
                      <Loader margin={"0px auto -50px auto"} />
                    )}
                    {currentChannelData && (
                      <div className="messageActiveUser c-pointer">
                        <div className="d-flex align-items-center">
                          <Grid sx={{ display: { xs: "block", md: "none" } }}>
                            <IconButton
                              aria-label="delete"
                              size=""
                              sx={{
                                bgcolor: COLORS.white,
                                borderRadius: 2,
                                marginLeft: -1,
                                mr: 2,
                                color: "#222"
                              }}
                              onClick={() => {
                                navigate("/messages")
                                handleChange("selectedChat", null)
                              }}
                            >
                              <ArrowBackIcon color="#222" />
                            </IconButton>
                          </Grid>
                          {currentChannelData?.group_name ===
                            "Unnamed Chat Group" ? (
                            <img
                              src={printName(getOtherUser1(user?.id))?.ProfileDP}
                              className={"mr-2 userprofile"}
                              onClick={() =>
                                currentChannelData?.group_name ===
                                "Unnamed Chat Group" &&
                                handleUserClick(getOtherUser1(user?.id))
                              }
                            />
                          ) : (
                            <div
                              className="d-flex mr-2"
                              onClick={() =>
                                currentChannelData?.group_name ===
                                "Unnamed Chat Group" &&
                                handleUserClick(getOtherUser1(user?.id))
                              }
                            >
                              {currentChannelData?.users?.map((user, index) => {
                                if (index < 2) {
                                  return (
                                    <img
                                      src={printName(user)?.ProfileDP}
                                      style={{ marginTop: index }}
                                      className={"groupuserprofile"}
                                    />
                                  )
                                }
                                return null
                              })}
                            </div>
                          )}
                          <div
                            className="chatName1"
                            onClick={() =>
                              currentChannelData?.group_name ===
                              "Unnamed Chat Group" &&
                              handleUserClick(getOtherUser1(user?.id))
                            }
                          >
                            {currentChannelData?.group_name ===
                              "Unnamed Chat Group"
                              ? printName(getOtherUser1(user?.id))?.ProfileName
                              : currentChannelData?.group_name}
                          </div>
                          {currentChannelData?.group_name !==
                            "Unnamed Chat Group" && (
                              <InfoIcon
                                className="cursor ml-2"
                                onClick={() =>
                                  handleChange("showGroup", !showGroup)
                                }
                              />
                            )}
                        </div>
                        <IconButton onClick={() =>
                          handleChange("showDeleteChatConfirm", true)
                        }>
                          <DeleteOutlineIcon />
                        </IconButton>
                      </div>
                    )}
                    {!loadingGetting && (
                      <Box
                        sx={{
                          height: "80%",
                          width: "100%",
                          display: "flex",
                          flexDirection: "column-reverse",
                          overflowY: "auto"
                        }}
                      >
                        <div id={"el"} ref={divRef}></div>
                        {messages?.map((message, index) => {
                          const isRoute =
                            message?.text?.includes("http://") ||
                            message?.text?.includes("https://")
                          const isMessageToFocus = Number(messageIdFromHash) === Number(message?.id);
                          return (
                            <ChatContent
                              isMessageToFocus={isMessageToFocus}
                              message={message}
                              index={index}
                              messageRefs={messageRefs}
                              user={user}
                              printName={printName}
                              getOtherUser={getOtherUser}
                              currentChannelData={currentChannelData}
                              isRoute={isRoute}
                              handleUserClick={handleUserClick}
                              _addReaction={_addReaction}
                              loadingReact={loadingReact}
                            />
                          )
                        })}
                      </Box>
                    )}
                  </div>
                  {
                    currentChannelData &&
                    <>
                      {
                        currentChannelData?.group_name !== "Unnamed Chat Group" && !currentChannelData?.messages_allowed && currentChannelData?.admin !== user?.id
                          ?
                          <div className="inputDiv text-center divCenter pt-2">
                            Only admin can send messages
                          </div>
                          :
                          <div className="inputDiv">
                            <textarea
                              className={"inputBox1 mr-3"}
                              ref={inputRef}
                              onFocus={() => handleChange("show", false)}
                              // onKeyPress={e => {
                              //   if (e.key === "Enter") sendMessage(message)
                              // }}
                              value={message}
                              name={"message"}
                              onChange={message => {
                                setState(prevState => ({
                                  ...prevState,
                                  message: message.target.value
                                }))
                              }}
                              placeholder={"Type message"}
                            />
                            <div className="sendDiv">
                              {show && (
                                <div className="EmojiPicker">
                                  <EmojiPicker
                                    onEmojiClick={onClickEmoji}
                                    categories={[
                                      { category: Categories.SMILEYS_PEOPLE },
                                      { category: Categories.ACTIVITIES },
                                      { category: Categories.ANIMALS_NATURE },
                                      { category: Categories.FOOD_DRINK },
                                      { category: Categories.OBJECTS },
                                      { category: Categories.SYMBOLS },
                                      { category: Categories.TRAVEL_PLACES }
                                    ]}
                                    emojiStyle="facebook"
                                    showPreview={true}
                                  />
                                </div>
                              )}
                              <div className="d-flex align-items-center pl-3">
                                <SmileIcon
                                  className="cursor"
                                  onClick={() => {
                                    inputRef.current?.blur()
                                    handleChange("show", !show)
                                  }}
                                />
                                <input
                                  ref={hiddenFileInput}
                                  type="file"
                                  accept="image/*"
                                  onChange={onFileChange}
                                  style={{ display: "none" }}
                                />
                                <AttachmentIcon
                                  className="cursor ml-2 mr-2"
                                  onClick={() => {
                                    inputRef.current?.blur()
                                    openPicker()
                                  }}
                                />
                                {/* <CopyBoardIcon className="cursor" /> */}
                              </div>
                              <SendIcon
                                className="cursor mr-3"
                                onClick={e => {
                                  e.preventDefault()
                                  sendMessage(message)
                                }}
                              />
                            </div>
                          </div>
                      }
                    </>
                  }
                </div>
              ) : null}
            </Grid>
            {showGroup && (
              <Grid
                item
                xs={12}
                md={3}
                container
                alignItems={"center"}
                direction={"column"}
                sx={{
                  display: "block",
                  textAlign: "center",
                  overflowY: "auto",
                  borderLeft: { xs: "none", md: "1px solid #0000000F" },
                  height: "70vh",
                  padding: 2
                }}
              >
                <Grid container>
                  <Grid sx={{ display: { xs: "block", md: "none" } }}>
                    <IconButton
                      aria-label="delete"
                      size=""
                      sx={{
                        bgcolor: COLORS.white,
                        borderRadius: 2,
                        color: "#222"
                      }}
                      onClick={() => {
                        handleChange("showGroup", false)
                      }}
                    >
                      <ArrowBackIcon color="#222" />
                    </IconButton>
                  </Grid>
                  <Grid
                    className="searchInputHeaderBox"
                    sx={{ width: { xs: "85%", md: "100%" } }}
                  >
                    <input
                      className="searchInput"
                      placeholder="Search..."
                      value={searchMemberText}
                      onChange={e =>
                        filtered("searchMemberText", e.target.value)
                      }
                    />
                    <div className="saerchIconHeaderDiv">
                      <SearchGrey />
                    </div>
                  </Grid>
                </Grid>
                <div className=" mt-4 mb-4">
                  {currentChannelData?.users?.map((user, index) => {
                    if (index < 2) {
                      return (
                        <img
                          src={printName(user)?.ProfileDP}
                          style={{ marginTop: index + 2 }}
                          className={"groupuserprofile2"}
                        />
                      )
                    }
                    return null
                  })}
                </div>
                <div className="font-bold font-16">
                  {currentChannelData?.group_name}
                  <PencilBlue
                    onClick={() => handleChange("changeNameGroup", true)}
                    className="ml-2 cursor"
                    style={{ marginTop: -3 }}
                  />
                </div>
                <AppButton
                  title={"Add member"}
                  onClick={() => handleChange("addMemberGroup", true)}
                  backgroundColor={COLORS.primary}
                  color={COLORS.white}
                  width={120}
                  height={40}
                  className={"mt-4"}
                  borderRadius={12}
                  fontWeight={300}
                />
                {
                  currentChannelData?.admin === user?.id &&
                  <div className="mt-2 d-flex align-items-center">
                    <Checkbox
                      onClick={() => {
                        _updateChannel({ messages_allowed: !currentChannelData?.messages_allowed })
                      }}
                      disabled={loadingChat}
                      checked={currentChannelData?.messages_allowed}
                      style={{
                        marginTop: -1
                      }}
                      sx={{
                        color: "rgba(201, 208, 216, 1)",
                        "&.Mui-checked": {
                          color: COLORS.primary
                        }
                      }}
                    />
                    <span>Allow message for members</span>
                  </div>
                }
                <div
                  style={{ width: "100%" }}
                  className="mt-3 d-flex align-items-center justify-content-between"
                >
                  <div>Members</div>
                  <div className="horizontalDivider" />
                </div>
                {Array.isArray(currentChannelData?.users) &&
                  currentChannelData?.users?.map((user, index) => (
                    <Grid
                      key={index}
                      className="mt-2"
                      container
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Grid
                        container
                        alignItems={"center"}
                        item
                        xs={8}
                        onClick={() => handleUserClick(user)}
                      >
                        <img
                          src={getImgSrc(user) ? getImgSrc(user) : AvatarIcon}
                          alt={getImgSrc(user)}
                          className="followerProfile"
                        />
                        <div>
                          {user?.name ||
                            user?.first_name + " " + user?.last_name ||
                            "No Name"}
                        </div>
                      </Grid>
                      <Checkbox
                        disabled={true}
                        checked={true}
                        style={{
                          marginTop: -3
                        }}
                        sx={{
                          color: "rgba(201, 208, 216, 1)",
                          "&.Mui-checked": {
                            color: COLORS.primary
                          }
                        }}
                      />
                    </Grid>
                  ))}
                <AppButton
                  loading={loadingLeave}
                  onClick={_leaveGroup}
                  title={"Leave Group"}
                  backgroundColor={COLORS.white}
                  color={"#000000D9"}
                  borderColor={"#D9D9D9"}
                  height={35}
                  className={"mt-4"}
                  fontWeight={400}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </section>
      <MessageModal
        visible={createGroup}
        type={"create"}
        handleClose={handleClose}
        _getChannels={_getChannels}
      />
      <DeleteChatModal
        _deleteChat={_deleteChat}
        visible={showDeleteChatConfirm}
        handleClose={() => handleChange("showDeleteChatConfirm", false)}
        loading={loadingDeleteChat}
      />
      <MessageModal
        visible={changeNameGroup}
        type={"changeName"}
        handleClose={handleClose}
        _getChannels={_getChannels}
        _getChannelMessages={_getChannelMessages}
        currentChannelData={currentChannelData}
      />
      <MessageModal
        visible={addMemberGroup}
        type={"addMember"}
        handleClose={handleClose}
        _getChannels={_getChannels}
        _getChannelMessages={_getChannelMessages}
        currentChannelData={currentChannelData}
      />
    </div>
  )
}
